import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./all-buildings.scss";
import { MainLayout, GoBack } from "@/components";
import { Archieve, Buildings } from "./sub-component";
import { useDispatch, useSelector } from "react-redux";
import { getBuildings } from "../../../redux/slice/buildingSlice";
import { getFirstPathSegment } from "../../../services/Utils";
import { useLocation } from "react-router-dom";

function AsbestosBuildings() {
  const { totalBuildings, totalArchievedBuildings } = useSelector(
    (state) => state.building
  );
  const location = useLocation()
  const [isArchieved, setIsArchived] = useState(false);
  const [activeTab, setActiveTab] = useState("all_building");
  const dispatch = useDispatch();
  const pathName = getFirstPathSegment(location?.pathname);

  function handleSelect(k) {
    setActiveTab(k);
    if (k === "archive") {
      setIsArchived(true);
    } else {
      setIsArchived(false);
    }
  }
  const getBuildingsFunc = async () => {
    if (isArchieved) {
      dispatch(getBuildings(pathName, "all", 1));
    } else {
      dispatch(getBuildings(pathName, "all"));
    }
  };
  useEffect(() => {
    getBuildingsFunc()
  }, [isArchieved]);
  return (
    <>
      <MainLayout buildingBgImage={true}>
        <div className="all-buildings-wrapper">
          <GoBack />
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="all_building"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleSelect}
          >
            <Tab
              eventKey="all_building"
              title={`All Buildings (${totalBuildings})`}
            >
              <Buildings />
            </Tab>
            <Tab
              eventKey="archive"
              title={`Archive (${totalArchievedBuildings})`}

              onSelect={() => {
                setIsArchived(true);;

                dispatch(getBuildings(pathName, "all", 1));
              }}
            >
              {/* <Archieve /> */}
              <Buildings
                isArchieved={isArchieved}
                setIsArchived={setIsArchived}
              />
            </Tab>
          </Tabs>
        </div>
      </MainLayout>
    </>
  );
}

export default AsbestosBuildings;
