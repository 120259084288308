import React, { useEffect, useState } from "react";
import { MainLayout, HeaderText, GoBack, InputText } from "@/components";
import SampleCard from "../samples/subComponent/sampleCard";
import "./result.scss";
import { DatePicker, Select } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addResult, addResultDetails, defaultResult, getCommon, setErrors } from "../../../redux/slice/resultsSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  checkEmpty,
  getFirstPathSegment,
  resultValidationObj,
} from "../../../services/Utils";

import { openNotificationWithIcon } from "../../../services/toast";
import dayjs from "dayjs";
function CreateResult() {
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname)
  const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName

  const params = useParams();
  const [layerTypeOptions, setlayerTypeOptions] = useState([])
  const { result_type, texture_color, uncommon_texture_color, sample_condition, errors, biofringence, ext_angle, elongation, asbestos_type_options, other_fibrous_options, non_fibrous_options, resultDetails, tech_id } = useSelector((state) => state.result)
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const presentKey = pathName === 'lead' ? 'LEAD_PRESENT' : 'ASB_PRESENT'

  const building = location?.state?.building
  const handleChange = (e, selectorName, date) => {
    let obj;
    if (date || date === '') {
      console.log(date)
      dispatch(addResultDetails({ RESULT_DATE: date }));
      dispatch(setErrors({ 'RESULT_DATE': null }, "..."));
    } else if (selectorName) {
      if (selectorName === 'TEXTURE_COMMON' || selectorName === 'TEXTURE_UNCOMMON') {
        obj = { 'TEXTURE': e }
        dispatch(setErrors({ 'TEXTURE': null }, "..."));
        // dispatch(addResultDetails())
      }
      else {

        if (selectorName === 'RES_TYPE') {
          e = Number(e)
        }

        obj = { [selectorName]: e }
      }
      dispatch(addResultDetails(obj));
      dispatch(setErrors({ [selectorName]: null }, "..."));

    }
    else {
      const { name, value } = e.target
      obj = { [name]: value };


      dispatch(addResultDetails(obj));

      if (name === 'MORPHOLOGY' || name === 'DIST_FEATURE') {
        dispatch(setErrors({ 'MORPHOLOGY': null }, "..."));
        dispatch(setErrors({ 'DIST_FEATURE': null }, "..."));
      } else {

        dispatch(setErrors({ [name]: null }, "..."));
      }

    }

  };


  function getCommonData() {
    dispatch(getCommon("result_type"));
    dispatch(getCommon("sample_condition"));
    dispatch(getCommon("texture_color"));
    dispatch(getCommon("tech_id"));
    dispatch(getCommon("uncommon_texture_color"));
    dispatch(getCommon("biofringence"));
    dispatch(getCommon("elongation"));
    dispatch(getCommon("ext_angle"));
    dispatch(getCommon("asbestos_type_options"));
    dispatch(getCommon("other_fibrous_options"));
    dispatch(getCommon("non_fibrous_options"));

  }


  useEffect(() => {
    getCommonData()
    console.log(location?.state?.SAMPLE_ID, params?.id, "sample-asasid")
    dispatch(addResultDetails({ SAMPLE_ID: params?.id + '' }))
    dispatch(addResultDetails({ BUILDING_ID: buildingId }))
    return (() => {
      dispatch(setErrors({}, "fullObj"))
    })
  }, [])

  useEffect(() => {
    return (() => {
      dispatch(defaultResult())
    })
  }, [])

  function handleMeasureChange(e) {
    const { value } = e.target

    const allFields = ['TRACE', 'RANGE', 'PERCENT'];
    allFields.forEach(field => {
      if (field !== value) {
        dispatch(addResultDetails({ [field]: null }));
      }
    });
    dispatch(addResultDetails({ [value]: 1 }))
  }

  function validateData(resultDetails) {
    let errors = {};
    let firstErrorField = null;
    console.log(resultDetails.RES_TYPE === '', "res")
    for (const key in resultDetails) {

      if (key === 'TRACE_AMT' || key === 'TRACE' || key === 'RANGE' || key === 'MATERIAL' || key === 'PERCENT' || key === 'update_reason') {
        // break;
      } else {
        console.log(key, 'keyy')
        if (
          resultDetails[key] === null ||
          resultDetails[key] === undefined ||
          resultDetails[key] === ""
        ) {

          if (key === 'MORPHOLOGY' || key === 'DIST_FEATURE') {

            if (checkEmpty(resultDetails['MORPHOLOGY']) && checkEmpty(resultDetails['DIST_FEATURE'])) {
              errors[key] = `Plese add atleast one from field from MORPHOLOGY OR DIST FEATURE`;
              firstErrorField = key;
            }
          }
          if (key === 'HOMOG' || key === presentKey || key === 'FPLA_ID' || key === 'RESULT_DATE' || key === 'TECH_ID' || key === 'LAYER_ID' || key === 'CATEGORY' || key === 'ASB_MATERIAL' || key === 'RES_TYPE') {
            errors[key] = `${resultValidationObj[key]} is required`;
            firstErrorField = key;
          }
        }
      }
    }
    console.log(errors, "eroooor")
    dispatch(setErrors(errors, "fullObj"));
    // return Object.keys(errors).length === 0 ? null : "Validation Error";
    return firstErrorField;
  }




  useEffect(() => {
    dispatch(addResultDetails({ MATERIAL: null }))
    if (resultDetails.CATEGORY === 'A') {
      setlayerTypeOptions(asbestos_type_options)
    } else if (resultDetails.CATEGORY === 'NF') {
      setlayerTypeOptions(non_fibrous_options)
    } else if (resultDetails.CATEGORY === 'OF') {
      setlayerTypeOptions(other_fibrous_options)
    }
  }, [resultDetails.CATEGORY])





  const handleSubmit = () => {
    try {
      const validate = validateData(resultDetails)
      console.log(validate, "validate")
      if (validate) {
        let elementsByName = document.getElementsByName(validate);
        if (elementsByName && elementsByName.length > 0) {
          const firstElement = elementsByName[0];
          firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return;
      }
      else {
        dispatch(addResultDetails({ 'ASB_ID': location?.state?.ASB_ID }));
        dispatch(addResultDetails({ 'SAMP_ASB_ID': params?.id }));
        dispatch(addResult())
        Navigate(`/${pathName}/results?id=${buildingId}`, {
          state: { building },
        });
      }
    } catch (error) {
      openNotificationWithIcon("error", error);
      console.log(error);
    }

  }
  return (
    <MainLayout>
      <div className="result-wrapper">
        <HeaderText>
          <GoBack />
          Results ·{" "}
          <span className="result-title">
            Add New Result · {building}
          </span>
        </HeaderText>
        <div className="create-result-wrapper d-flex flex-column gap-4">
          <SampleCard header={`Sample: ${location?.state?.SAMPLE_ID ?? ""}`}>
            <div className="row">
              <div className="col-4">
                <InputText
                  label="BUILDING ID"
                  errors={""}
                  disabled={true}
                  name="NO_LEVELS"
                  onChange={handleChange}
                  value={building || null}
                />
              </div>
              <div className="col-4">
                <div className="radio">
                  <label htmlFor="" className="label">
                    HOMOGENEOUS
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? true
                        //         : false
                        //   }
                        name="HOMOG"
                        id="HOMOG-yes"
                        onChange={handleChange}
                        value={1}
                      />
                      <label htmlFor="HOMOG-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? false
                        //         : true
                        //   }
                        name="HOMOG"
                        id="HOMOG-no"
                        onChange={handleChange}
                        value={0}
                      />

                      <label htmlFor="HOMOG-no">No</label>
                    </div>
                    {/* <p className="error-message">required*</p> */}
                    {/* {errors.INACCESS_AREA && (
                    <small className="error-message">
                      {errors.INACCESS_AREA}
                    </small>
                  )} */}
                  </div>
                </div>
                {errors.HOMOG && (
                  <small className="error-message">{errors.HOMOG}</small>
                )}
              </div>
              <div className="col-4">
                <div className="radio">
                  <label htmlFor="" className="label">
                    {pathName === 'asbestos' ? 'ASBESTOS' : 'LEAD'} PRESENT
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? true
                        //         : false
                        //   }
                        name={presentKey}
                        id={`${presentKey}-yes`}
                        onChange={handleChange}
                        value={1}
                      />
                      <label htmlFor={`${presentKey}-yes`}>Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? false
                        //         : true
                        //   }
                        name={`${presentKey}`}
                        id={`${presentKey}-no`}
                        onChange={handleChange}
                        value={0}
                      />
                      <label htmlFor={`${presentKey}-no`}>No</label>
                    </div>
                  </div>
                  {errors[presentKey] && (
                    <small className="error-message">{errors[presentKey]}</small>
                  )}
                </div>
              </div>
              <div className="col-4 mt-4">
                <InputText
                  label="FPLA ID"
                  name="FPLA_ID"
                  onChange={handleChange}
                  errors={errors.FPLA_ID}
                  value={resultDetails.FPLA_ID}
                />
              </div>
              <div className="col-4 mt-4">
                <label htmlFor="" className="label">
                  RESULT DATE
                </label>
                <DatePicker
                  name="RESULT_DATE"
                  onChange={(e, date) => handleChange(null, null, date)}
                  // value={
                  //   resultDetails.RESULT_DATE
                  //     ? moment(resultDetails.RESULT_DATE, "YYYY-MM-DD")
                  //     : null
                  // }
                  value={
                    resultDetails.RESULT_DATE ? dayjs(resultDetails.RESULT_DATE, 'YYYY-MM-DD') : null
                  }
                  format={'YYYY-MM-DD'}
                />
                {errors.RESULT_DATE && (
                  <small className="error-message">
                    {errors.RESULT_DATE}
                  </small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header={`Only if there are no results associated with this sample that are of ${category} Type`}>
            <div className="row">
              <div className="col-4">
                <InputText
                  label={`Distinguishing Feature for Non-${category} Fibers`}

                  name="DIST_FEATURE"
                  onChange={handleChange}
                  errors={errors.DIST_FEATURE}
                  value={resultDetails.DIST_FEATURE} />
              </div>
              <div className="col-4">
                <InputText
                  label="Morphology"
                  name="MORPHOLOGY"
                  onChange={handleChange}
                  errors={errors.MORPHOLOGY}
                  value={resultDetails.MORPHOLOGY}
                />
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Basic">
            <div className="row">
              <div className="col-4">
                <InputText
                  label="Sample Preparation"
                  name="SAMP_PREP"
                  onChange={handleChange}
                  errors={errors.SAMP_PREP}
                  value={resultDetails.SAMP_PREP} />
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  Sample Condition
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="SAMP_COND"
                  onChange={(e) => handleChange(e, "SAMP_COND")}
                  value={resultDetails.SAMP_COND}
                  options={
                    sample_condition.map((type) => (
                      { value: type.description, label: type.description }
                    ))
                  }
                />
                {errors.SAMP_COND && (
                  <small className="error-message">{errors.SAMP_COND}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  Texture/Color
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="TEXTURE_COMMON"
                  fieldNames={{ name: 'TEXTURE_COMMON' }}
                  onChange={(e) => handleChange(e, "TEXTURE_COMMON")}
                  value={uncommon_texture_color.map((obj) => obj.description).includes(resultDetails.TEXTURE) ? resultDetails.TEXTURE : null} options={
                    uncommon_texture_color.map((color) => (
                      { value: color?.description, label: color?.description }
                    ))
                  }
                />

              </div>
              <div className="col-4 mt-4">
                <label className="label" htmlFor="">
                  Uncommon Texture/Color
                </label>
                {console.log(texture_color, "casas")}
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="TEXTURE_UNCOMMON"
                  fieldNames={{ name: 'TEXTURE_UNCOMMON' }}
                  onChange={(e) => handleChange(e, "TEXTURE_UNCOMMON")}
                  value={texture_color.map((obj) => obj.description).includes(resultDetails.TEXTURE) ? resultDetails.TEXTURE : null}
                  options={
                    texture_color.map((type) => (
                      { value: type?.description, label: type.description }
                    ))
                  }
                />
                {/* {errors.TEXTURE_UNCOMMON && (
                <small className="error-message">{errors.TEXTURE_UNCOMMON}</small>
              )} */}
              </div>
              <div className="col-4 mt-4">
                <label className="label" htmlFor="">
                  Tech ID
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="TECH_ID"
                  fieldNames={{ name: 'TECH_ID' }}
                  onChange={(e) => handleChange(e, "TECH_ID")}
                  value={resultDetails.TECH_ID}
                  // options={[
                  //   { value: "ah", label: "AH" },
                  //   { value: "kc", label: "KC" },
                  //   { value: "ejs", label: "EJS" },
                  //   { value: "sg", label: "SG" },
                  //   { value: "cea", label: "CEA" },
                  //   { value: "sjs", label: "SJS" },
                  //   { value: "tv", label: "TV" },
                  //   { value: "cr", label: "CR" },
                  //   { value: "tb", label: "TB" },
                  //   { value: "ga", label: "GA" },
                  //   { value: "rdn", label: "RDN" },
                  //   { value: "seh", label: "SEH" },
                  //   { value: "bp", label: "BP" },
                  //   { value: "gh", label: "GH" },
                  //   { value: "eed", label: "EED" },
                  //   { value: "kw", label: "KW" },
                  //   { value: "vs", label: "VS" },
                  //   { value: "ed", label: "ED" },
                  //   { value: "nrc", label: "NRC" },
                  //   { value: "jb", label: "JB" },
                  //   { value: "bb", label: "BB" },
                  //   { value: "ba", label: "BA" },
                  //   { value: "vw", label: "VW" },
                  //   { value: "ca", label: "CA" },
                  //   { value: "sh", label: "SH" },
                  //   { value: "tgv", label: "TGV" },
                  //   { value: "jab", label: "JAB" },
                  //   { value: "cd", label: "CD" },
                  //   { value: "sk", label: "SK" },
                  //   { value: "kec", label: "KEC" },
                  //   { value: "mg", label: "MG" },
                  //   { value: "rnd", label: "RND" },
                  //   { value: "cb", label: "CB" },
                  //   { value: "JG", label: "JG" },
                  //   { value: "mvr", label: "MVR" },
                  //   { value: "gt", label: "GT" },
                  //   { value: "jm", label: "JM" },
                  //   { value: "ap", label: "AP" },
                  //   { value: "gjh", label: "GJH" },
                  //   { value: "gas", label: "GAS" },

                  // ]}
                  options={
                    tech_id.map((type) => (
                      { value: type?.description, label: type?.description }
                    ))
                  }
                />
                {errors.TECH_ID && (
                  <small className="error-message">{errors.TECH_ID}</small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Layer Data">
            <div className="row">
              <div className="col-4">
                <InputText
                  label="Layer"
                  name="LAYER_ID"
                  onChange={handleChange}
                  value={resultDetails.LAYER_ID}
                  errors={errors.LAYER_ID}
                />
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  Type
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="CATEGORY"
                  fieldNames={{ name: 'CATEGORY' }}
                  onChange={(e) => handleChange(e, "CATEGORY")}
                  value={resultDetails.CATEGORY}
                  options={[
                    { value: pathName == 'asbestos' ? "A" : 'LD', label: pathName == 'asbestos' ? "Asbestos" : 'Lead' },
                    { value: "ND", label: "Non-Detect" },
                    // { value: "LD", label: "Lead" },
                    { value: "NF", label: "Non-Fibrous" },
                    { value: "OF", label: "Other-Fibrous" },
                  ]}
                />
                {errors.CATEGORY && (
                  <small className="error-message">{errors.CATEGORY}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  {resultDetails.CATEGORY === "A" ? "**Asbestos Type" : resultDetails.CATEGORY === "NF" ? "Non-Fibrous Form" : resultDetails.CATEGORY === "OF" ? "Non-Asbestos fibres" : resultDetails.CATEGORY === "ND" ? "Result Type" : "Lead Type"}
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="MATERIAL"
                  disabled={resultDetails.CATEGORY === 'ND'}
                  fieldNames={{ name: 'MATERIAL' }}
                  onChange={(e) => handleChange(e, "MATERIAL")}
                  value={resultDetails.MATERIAL}
                  options={
                    layerTypeOptions.map((layer) => (
                      { value: layer?.description, label: layer?.description }
                    ))
                  }
                />
                {errors.MATERIAL && (
                  <small className="error-message">{errors.MATERIAL}</small>
                )}
              </div>
              <div className="col-4 mt-4">
                <InputText
                  label={`${category} Found in Material`}
                  name="ASB_MATERIAL"
                  onChange={handleChange}
                  errors={errors.ASB_MATERIAL}
                  value={resultDetails.ASB_MATERIAL} />
              </div>
              <div className="col-8 mt-4 pt-4">
                <div className="radio">
                  {/* <label htmlFor="" className="label">
                    HOMOGENEOUS
                  </label> */}
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? true
                        //         : false
                        //   }
                        name="MeasurementType"
                        id="MeasurementType-P"
                        onChange={handleMeasureChange}
                        value='PERCENT'
                      />
                      <label htmlFor="MeasurementType-P">
                        Use Numeric Percentage
                      </label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? false
                        //         : true
                        //   }
                        name="MeasurementType"
                        id="MeasurementType-R"
                        onChange={handleMeasureChange}
                        value='RANGE'
                      />
                      <label htmlFor="MeasurementType-R">Use Range</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? false
                        //         : true
                        //   }
                        name="MeasurementType"
                        id="MeasurementType-T"
                        onChange={handleMeasureChange}
                        value='TRACE'
                      />
                      <label htmlFor="MeasurementType-T">Use Trace</label>
                    </div>
                    {resultDetails.RANGE || resultDetails.PERCENT ?
                      <div className="col-4">
                        <InputText
                          label={resultDetails.PERCENT ? "PERCENT" : "RANGE(ex 5-10)"}
                          name="PERCENT_RANGE"
                          errors={errors.PERCENT_RANGE}
                          onChange={handleChange}
                          value={resultDetails.PERCENT_RANGE}
                        />
                      </div>
                      : ""
                    }
                  </div>
                </div>
              </div>
              <div className="col-4 mt-4">
                <label className="label" htmlFor="">
                  Result Type
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="RES_TYPE"
                  onChange={(e) => handleChange(e, "RES_TYPE")}
                  value={resultDetails.RES_TYPE}
                  options={
                    result_type.map((type) => (
                      { value: type.id, label: type.description }
                    ))
                  }
                />
                {errors.RES_TYPE && (
                  <small className="error-message">{errors.RES_TYPE}</small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header={`Sample: ${location?.state?.SAMPLE_ID ?? ""}`}>
            <div className="row">

              <div className="col-4">
                <div className="radio">
                  <label htmlFor="" className="label">
                    Pleochroism
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? true
                        //         : false
                        //   }
                        name="PLEOCH"
                        id="PLEOCH-yes"
                        onChange={handleChange}
                        value={1}
                      />
                      <label htmlFor="PLEOCH-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        //   defaultChecked={
                        //     !surveyId
                        //       ? undefined
                        //       : surveyDetails.INACCESS_AREA
                        //         ? false
                        //         : true
                        //   }
                        name="PLEOCH"
                        id="PLEOCH-no"
                        onChange={handleChange}
                        value={0}
                      />
                      <label htmlFor="PLEOCH-no">No</label>
                    </div>

                    {/* <p className="error-message">required*</p> */}
                    {/* {errors.INACCESS_AREA && (
                    <small className="error-message">
                      {errors.INACCESS_AREA}
                    </small>
                  )} */}
                  </div>
                  {errors.PLEOCH && (
                    <small className="error-message">{errors.PLEOCH}</small>
                  )}
                </div>
              </div>

              <div className="col-4">
                <label className="label" htmlFor="">
                  Biofringence
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="BIOFRING"
                  onChange={(e) => handleChange(e, "BIOFRING")}
                  value={resultDetails.BIOFRING}
                  // options={[
                  //   { value: "Low", label: "Low" },
                  //   { value: "Mod", label: "Mod" },
                  //   { value: "High", label: "High" },
                  // ]}
                  options={
                    biofringence.map((type) => (
                      { value: type.description, label: type.description }
                    ))
                  }
                />
                {errors.BIOFRING && (
                  <small className="error-message">{errors.BIOFRING}</small>
                )}
              </div>
              {/* <div className="col-4">
                <label className="label" htmlFor="">
                  Reflective Indices (nm)
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="PLUMBING_TYPE"
                  onChange={(e) => handleChange(e, "PLUMBING_TYPE")}
                  //   value={surveyDetails.PLUMBING_TYPE}
                  options={[
                    { value: "Cold Water Only", label: "Cold Water Only" },
                    {
                      value: "Complete Toilet / Bathing Facilities",
                      label: "Complete Toilet / Bathing Facilities",
                    },
                    {
                      value: "Hot & Cold Water Only",
                      label: "Hot & Cold Water Only",
                    },
                    { value: "None", label: "None" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div> */}
              <div className="col-4">
                <InputText
                  label="Reflective Index"
                  name="REF_INDEX"
                  errors={errors.REF_INDEX}
                  onChange={handleChange}
                  value={resultDetails.REF_INDEX}
                />
              </div>
              <div className="col-4 mt-4">
                <InputText
                  label="Average Reflective Index"
                  errors={errors.AVG_REF_INDEX}
                  name="AVG_REF_INDEX"
                  onChange={handleChange}
                  value={resultDetails.AVG_REF_INDEX}
                />
              </div>

              <div className="col-4 mt-4">
                <label className="label" htmlFor="">
                  Sign of Elongation
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="ELONG"
                  onChange={(e) => handleChange(e, "ELONG")}
                  value={resultDetails.ELONG}
                  options={
                    elongation.map((type) => (
                      { value: type.description, label: type.description }
                    ))
                  }
                />
                {errors.ELONG && (
                  <small className="error-message">{errors.ELONG}</small>
                )}
              </div>
              <div className="col-4 mt-4">
                <label className="label" htmlFor="">
                  Extinction Angle
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="EXT_ANGLE"
                  onChange={(e) => handleChange(e, "EXT_ANGLE")}
                  value={resultDetails.EXT_ANGLE}
                  // options={[
                  //   { value: "Incomplete", label: "Incomplete" },
                  //   { value: "Oblique", label: "Oblique" },
                  //   { value: "Parallel", label: "Parallel" },
                  // ]}
                  options={
                    ext_angle.map((type) => (
                      { value: type.description, label: type.description }
                    ))
                  }
                />
                {errors.EXT_ANGLE && (
                  <small className="error-message">{errors.EXT_ANGLE}</small>
                )}
              </div>
              {/* <div className="col-4 mt-4">
                <InputText
                  label="Average Reflective Index for y/a"
                  errors={""}
                  name="NO_LEVELS"
                  disabled={true}
                  onChange={handleChange}
                  value={""}
                />
              </div> */}
              {/* <div className="col-4 mt-4">
                <InputText
                  label="Average Reflective Index for y/a"
                  errors={""}
                  name="NO_LEVELS"
                  onChange={handleChange}
                  value={""}
                />
              </div> */}
              <div className="col-12 mt-4">
                <label className="label" htmlFor="">
                  COMMENTS{" "}
                </label>
                <textarea
                  name="COMMENTS"
                  id=""
                  rows="3"
                  onChange={handleChange}
                  value={resultDetails.COMMENTS}
                ></textarea>
              </div>
              {errors.COMMENTS && (
                <small className="error-message">{errors.COMMENTS}</small>
              )}
            </div>
          </SampleCard>

          <div className="submit-btns d-flex">
            <button className="button primary-button mx-auto" onClick={handleSubmit}>
              Save New Result
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default CreateResult;
