import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";
import { checkEmpty } from "../../services/Utils";

const initialState = {
  results: [],
  totalResults: 0,
  currentPage: 1,
  resultDetails: {
    EXT_ANGLE: "",
    REF_INDEX: "",
    AVG_REF_INDEX: "",
    DIST_FEATURE: "",
    MORPHOLOGY: "",
    ASB_ID: "",
    FPLA_ID: "",
    TECH_ID: "",
    RESULT_DATE: "",
    HOMOG: "",
    ASB_PRESENT: "",
    LEAD_PRESENT: "",
    PLEOCH: "",
    BIOFRING: "",
    ELONG: "",
    RANGE: null,
    MATERIAL: "",
    RES_TYPE: "",
    PERCENT: null,
    LAYER_ID: "",
    CATEGORY: "",
    SAMP_PREP: "",
    TEXTURE: "",
    SAMP_COND: "",
    COMMENTS: "",
  },
  isLoading: false,
  errors: {},
  result_type: [],
  history: [],
  sample_condition: [],
  tech_id: [],
  biofringence: [],
  ext_angle: [],
  elongation: [],
  asbestos_type_options: [],
  other_fibrous_options: [],
  non_fibrous_options: [],
  texture_color: [],
  uncommon_texture_color: [],
};

const resultSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = true;
    },
    getCommon(state, action) {
      console.log(action, "adadsddsaasd");
      state[Object.keys(action.payload).toString()] = Object.values(
        action.payload
      ).flat();

      state.isLoading = false;
    },
    defaultResultsBySample(state, action) {
      state.results = [];
    },
    defaultResult(state, action) {
      state.resultDetails = {
        EXT_ANGLE: "",
        REF_INDEX: "",
        AVG_REF_INDEX: "",
        DIST_FEATURE: "",
        MORPHOLOGY: "",
        FPLA_ID: "",
        TECH_ID: "",
        COMMENTS: "",
        RESULT_DATE: "",
        HOMOG: "",
        ASB_PRESENT: "",
        LEAD_PRESENT: "",
        PLEOCH: "",
        BIOFRING: "",
        ELONG: "",
        RES_TYPE: "",
        ASB_MATERIAL: "",
        RANGE: null,
        MATERIAL: "",
        PERCENT: null,
        LAYER_ID: "",
        CATEGORY: "",
        SAMP_PREP: "",
        TEXTURE: "",
        SAMP_COND: "",
      };
    },
    getResultData(state, action) {
      state.resultDetails = action.payload;
      state.isLoading = false;
    },
    addResultDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];

      state.resultDetails[key] = value;
    },
    startLoading(state, action) {
      state.isLoading = true;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
    },
    getResults(state, action) {
      if (state.currentPage == 1) {
        state.totalResults = action.payload.totalResults;
      }
      state.results = action.payload.results;
      state.isLoading = false;
    },
    getHistory(state, action) {
      state.history = action.payload.results;
      state.isLoading = false;
    },

    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
  },
});

export const {
  changePage,
  addResultDetails,
  setErrors,
  defaultResultsBySample,
  defaultResult,
} = resultSlice.actions;

export function getResults(id, category, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const resultsState = getState();
      const currentPage = resultsState.result.currentPage;
      const response = await API.get(
        `${ApiEndPoint.allResults}/${id}?skip=${(currentPage - 1) * 10}&category=${category}&limit=10${search ? `&search=${search}` : ""}`
      );
      const results = response?.data?.data?.listing;

      const totalResults = response?.data?.data?.count;
      dispatch({
        type: "results/getResults",
        payload: { results, totalResults },
      });
    } catch (error) {
      dispatch({ type: "results/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}
export function getResultsRecord(id, category) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const endPoint = ApiEndPoint.resultsRecord.split("/");
      const suffix = endPoint.pop();
      const response = await API.get(
        `${endPoint.join("/")}/${id}/${suffix}?category=${category}`
      );
      const results = response?.data?.data?.listing;
      return results;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "results/stopLoading" });
    }
  };
}

export function getResultData(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    dispatch({ type: "results/defaultResult" });
    try {
      const response = await API.get(`${ApiEndPoint.result}/${id}`);

      const results = response?.data?.data;

      // const allowedKeys = Object.keys(initialState.resultDetails);
      // const filteredResultData = Object.keys(resultData)
      //   .filter((key) => allowedKeys.includes(key))
      //   .reduce((obj, key) => {
      //     obj[key] = resultData[key];
      //     return obj;
      //   }, {});

      dispatch({
        type: "results/getResults",
        payload: { results, totalResults: 0 },
      });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getHistoryByResult(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    // dispatch({ type: "results/defaultResult" });
    try {
      const resultsState = getState();
      const currentPage = resultsState.result.currentPage;
      const response = await API.get(`${ApiEndPoint.resultsHistory}/${id}`);
      const results = response?.data?.data?.listing;

      const totalResults = response?.data?.data?.count;
      dispatch({
        type: "results/getHistory",
        payload: { results, totalResults },
      });
      return results;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getResultsBySample(id, category) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    // dispatch({ type: "results/defaultResult" });
    try {
      const resultsState = getState();
      const currentPage = resultsState.result.currentPage;
      const response = await API.get(
        `${ApiEndPoint.resultsBySample}/${id}?skip=${(currentPage - 1) * 10}&limit=10&category=${category}`
      );

      const results = response?.data?.data.result;
      const totalResults = response?.data?.data?.totalCount;

      // const allowedKeys = Object.keys(initialState.resultDetails);
      // const filteredResultData = Object.keys(resultData)
      //   .filter((key) => allowedKeys.includes(key))
      //   .reduce((obj, key) => {
      //     obj[key] = resultData[key];
      //     return obj;
      //   }, {});

      // dispatch({ type: "results/getResultData", payload: resultData });
      dispatch({
        type: "results/getResults",
        payload: { results, totalResults },
      });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "results/stopLoading" });
    }
  };
}

export function getCommon(term) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.common}?type=${term}&status=active`
      );

      dispatch({
        type: "results/getCommon",
        payload: { [term]: response?.data?.data },
      });
    } catch (error) {
      console.log(error, `${term} error`);
    }
  };
}

// export function getCommon(term) {
//   return async function (dispatch, getState) {
//     dispatch({ type: "results/startLoading" });
//     try {
//       const response = await API.get(ApiEndPoint[term]);
//       console.log(response,"----------res",term)
//       dispatch({
//         type: "results/getCommon",
//         payload: { [term]: response?.data?.data },
//       });
//     } catch (error) {
//       console.log(error, `${term} error`);
//     }
//   };
// }

export function addResult() {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const resultDetails = getState().result.resultDetails;
      const newResultDetails = JSON.parse(JSON.stringify(resultDetails));
      for (const item in newResultDetails) {
        if (checkEmpty(newResultDetails[item])) {
          delete newResultDetails[item];
        }
      }
      const response = await API.post(ApiEndPoint.result, newResultDetails);
      openNotificationWithIcon("success", "Result created successfully");
      dispatch({ type: "results/defaultResult" });
    } catch (error) {
      console.log(error, "error");
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "results/stopLoading" });
    }
  };
}

export function deleteResult(id, DELETE_REASON) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const response = await API.delete(`${ApiEndPoint.result}/${id}`, {
        data: { DELETE_REASON },
      });
      // dispatch(getResults(buildingId));
      dispatch({ type: "results/changePage", payload: 1 });
      openNotificationWithIcon("success", "Result deleted successfully");
    } catch (error) {
      openNotificationWithIcon("error", "Something went wrong");
    } finally {
      dispatch({ type: "results/stopLoading" });
    }
  };
}

export function updateResult(changedData) {
  return async function (dispatch, getState) {
    dispatch({ type: "results/startLoading" });
    try {
      const newResultDetails = JSON.parse(JSON.stringify(changedData));
      for (const item in newResultDetails) {
        if (checkEmpty(newResultDetails[item])) {
          delete newResultDetails[item];
        }
      }
      const response = await API.put(`${ApiEndPoint.result}`, newResultDetails);
      openNotificationWithIcon("success", "Result has been Updated");
      // dispatch({type:"building/defaultBuilding"})
    } catch (error) {
      dispatch({ type: "results/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
      console.log(error?.response?.data?.message);
    }
  };
}
export default resultSlice.reducer;
