import { useNavigate } from "react-router-dom/dist";
import { useState } from "react";
import "./login.scss";
import { InputText } from "@/components";
import API from "@/services/ApiService";
import { ApiEndPoint } from "@/services/ApiEndpoint";
import { openNotificationWithIcon } from "@/services/toast";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { setGlobalState } from "../../redux/slice/globalStates";

function LoginContent() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  function validateData({ email, password }) {
    let errors = {};
    if (!email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
    }
    if (!password) {
      errors.password = "Password Required";
    } else if (password.length < 3) {
      errors.password = "Invalid Password ";
    }
    setErrors(errors);

    return Object.keys(errors).length === 0 ? null : "Validation Error";
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const validate = validateData(userDetails);
    if (validate) {
      return;
    } else {
      API.post(ApiEndPoint.login, userDetails)
        .then(function (response) {
          const userData = response?.data?.data;
          if (response?.data?.data?.isVerified === false) {
            dispatch(
              setGlobalState({
                path: "login",
                showOTPmodalOpen: true,
                showVerifyEmail: true,
                forgotEmail: userDetails.email,
              })
            );
          }
          // console.log(response?.data?.data?.isVerified, "poooop")
          var token = response?.data?.data?.accessToken;
          if (!token) {
            navigate("/login");
          } else {
            localStorage.setItem("token", token);
            localStorage.setItem("userData", JSON.stringify(userData));
            navigate("/asbestos");
          }
        })
        .catch((error) => {
          console.log(error);
          openNotificationWithIcon("error", error?.response?.data?.message);
        });
    }
  };
  const showForgotPassword = () =>
    dispatch(setGlobalState({ showForgotPassword: true }));
  return (
    <form onSubmit={handleLogin} style={{ width: "24rem" }}>
      <h3 className="login-header" style={{ letterSpacing: "1px" }}>
        Log in
      </h3>
      <p className="login-desc">
        Enter your account details to login our dashboard.
      </p>

      <div className="login-input">
        <InputText
          label="Email ID"
          type="email"
          onChange={(e) => {
            setUserDetails((prev) => ({
              ...prev,
              email: e.target.value,
            }));
            setErrors((prev) => ({ ...prev, email: null }));
          }}
          placeholder="Enter your email"
        />
        {errors.email && <p className="error-message">{errors.email}</p>}
      </div>
      <div className="login-input">
        <label htmlFor="" className="label">
          Password
        </label>
        <Input.Password
          placeholder="Input password"
          onChange={(e) => {
            setUserDetails((prev) => ({
              ...prev,
              password: e.target.value,
            }));
            setErrors((prev) => ({ ...prev, password: null }));
          }}
        />
        {/* <InputText
        label="Password"
        type="password"
        placeholder="Enter your password"
        onChange={(e) => {
          setUserDetails((prev) => ({
            ...prev,
            password: e.target.value,
          }));
          setErrors((prev) => ({ ...prev, password: null }));
        }}
      /> */}
        {errors.password && <p className="error-message">{errors.password}</p>}
      </div>
      <div className="forgot-link">
        <button type="button" onClick={showForgotPassword}>
          Forgot Password?
        </button>
      </div>
      <div className="login-button">
        <button
          className="primary-button button w-100"
          type="submit"
          // onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </form>
  );
}

export default LoginContent;
