import React, { useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { MainLayout, HeaderText, SearchInput, InputText } from "@/components";
import {
  addUser,
  addUserDetail,
  changePage,
  defaultUser,
  deleteUser,
  getCommon,
  getUsers,
  setErrors,
  startLoading,
  updateAccesstoState,
  updateUser,
} from "../../redux/slice/userSlice";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import CheckIcon from "@/assets/images/check-icon.svg";
import "./user.scss";
import { Drawer, Empty, Modal, Pagination, Select, Spin, Switch } from "antd";
import { Checkbox } from "../../components";
import { openNotificationWithIcon } from "../../services/toast";
import { useDispatch, useSelector } from "react-redux";
import InventoryModal from "../../components/modals/InventoryModal";
import { checkAccess } from "../../services/Utils";
const { confirm } = Modal;
function Users() {
  const dispatch = useDispatch();
  const {
    users: allUsers,
    totalUsers,
    currentPage,
    isLoading,
    userDetails,
    error,
    designation,
    currentUser,
    hasUpdateAccess,
  } = useSelector((state) => state.user);
  const [selecetedUser, setSelectedUser] = useState({});
  const [changedData, setChangedData] = useState({});
  const [openInventoryModal, setInventoryModal] = useState(false);
  const [isAccessable, setIsAccessable] = useState(true);

  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState([
    // { label: "User Admin", checked: false, key: 'subAdmin' },
    { label: "Sample - Add", checked: false, key: "sampleAdd" },
    { label: "Building - Add", checked: false, key: "buildingAdd" },
    { label: "Sample - Edit", checked: false, key: "sampleEdit" },
    { label: "Building - Edit", checked: false, key: "buildingEdit" },
    { label: "Sample - Delete", checked: false, key: "sampleDelete" },
    { label: "Building - Delete", checked: false, key: "buildingDelete" },
    { label: "Result - Add", checked: false, key: "resultAdd" },
    { label: "Survey - Add", checked: false, key: "surveyAdd" },
    { label: "Result - Edit", checked: false, key: "resultEdit" },
    { label: "Survey - Edit", checked: false, key: "surveyEdit" },
    { label: "Result - Delete", checked: false, key: "resultDelete" },
    { label: "Survey - Delete", checked: false, key: "surveyDelete" },
    { label: "List - Manage", checked: false, key: "manageList" },
  ]);

  async function checkAccessLocal() {
    const updateAccess = await checkAccess(currentUser, "fsdg");
    console.log(updateAccess, "access");
    setIsAccessable(updateAccess);
  }
  useEffect(() => {
    getUsersFunc();
  }, [currentPage]);
  useEffect(() => {
    dispatch(getCommon("designation"));
  }, []);

  function updateUserFunc(id) {
    setOpen(true);
    const currentUser = allUsers.filter((user) => user.id == id);

    if (currentUser) {
      const access_rights = JSON.parse(currentUser[0].access_rights || "[]");
      const updateCheckboxOptions = checkboxOptions.map((option) => ({
        ...option,
        checked: access_rights.includes(option.key),
      }));
      setCheckboxOptions(updateCheckboxOptions);
      setSelectedUser(...currentUser);
    }
  }

  useEffect(() => {
    return async () => {
      dispatch(defaultUser());
    };
  }, []);
  async function getUsersFunc() {
    try {
      dispatch(getUsers());
    } catch (error) {
      openNotificationWithIcon("error", "something went wrong");
    }
  }

  // const showDrawer = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    setOpen(false);
  };
  const handleChecked = (item) => {
    let data = checkboxOptions.map((i) => {
      if (i.label === item.label) {
        i.checked = !i.checked;
      }
      return i;
    });
    setCheckboxOptions(data);

    const selectedAccessRights = data
      .filter((option) => option.checked)
      .map((option) => option.key);

    handleChangeUpdate({
      target: {
        name: "access_rights",
        value: JSON.stringify(selectedAccessRights),
      },
    });
  };

  function validateDetails(userDetails) {
    let errors = {};
    for (const key in userDetails) {
      console.log(key, "key");
      const trimmedValue = (userDetails[key] + '').trim();
      if (!trimmedValue) {
        errors[key] = `${key} is required!`;
      } else {
        if (key == "password" && trimmedValue.length <= 3) {
          errors[key] = `Invalid ${key}!`;
        }
        if (key == "email") {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userDetails[key])
          ) {
            errors[key] = `Invalid ${key}!`;
          }
        }
      }
      if (!userDetails[key]) {
        errors[key] = `${key} is required!`;
      }
    }
    dispatch(setErrors(errors, "fullObj"));
    return Object.keys(errors)?.length === 0 ? null : "Validation Error";
  }
  function onDelete(id) {
    confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
        onClose();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  async function handleDelete(id) {
    try {
      dispatch(deleteUser(id));
      openNotificationWithIcon("success", "User deleted Successfully");
    } catch (error) {
      openNotificationWithIcon("error", "something went wrong");
    }
  }

  function handlePageChange(page) {
    dispatch(startLoading());
    dispatch(changePage(page));
  }
  const handleOk = async () => {
    const validate = validateDetails(userDetails);
    if (validate) {
      return;
    } else {
      try {
        dispatch(addUser());
        dispatch(getUsers());
        setModalOpen(false);
        await getUsersFunc();
      } catch (error) {
        openNotificationWithIcon("error", error?.response?.data?.message);
      }
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    // const trimmedValue = value.trim();
    // const userData = { [name]: trimmedValue };
    const userData = { [name]: value };
    console.log(userData);
    dispatch(addUserDetail(userData));
    dispatch(setErrors({ [name]: null }, "..."));
  }

  function handleChangeUpdate(e) {
    const { name, value } = e.target;
    // const trimmedValue = value.trim();
    // const data = { [name]: trimmedValue };
    const data = { [name]: value };
    setSelectedUser((prev) => ({
      ...prev,
      ...data,
    }));

    setChangedData((prev) => ({
      ...prev,
      ...data,
    }));
  }

  function findRequiredKeys(obj) {
    let requiredKeys = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          (typeof value === "string" && value.trim() === "")
        ) {
          requiredKeys.push(key);
        }
      }
    }
    return requiredKeys;
  }

  function handleUpdate() {
    if (!Object.keys(changedData)?.length) {
      return openNotificationWithIcon(
        "error",
        "Please add some data to update"
      );
    }
    if (changedData?.password?.length < 4)
      return openNotificationWithIcon("error", "Password is very short");
    const valid = findRequiredKeys(changedData);
    if (valid.length)
      return openNotificationWithIcon(
        "error",
        "Please add some data to update"
      );
    dispatch(updateUser(changedData, selecetedUser?.id));
    setChangedData({});
    onClose();
  }

  useEffect(() => {
    const verifyAccess = async () => {
      const updateAccess = await checkAccess(currentUser, "manageList");
      dispatch(updateAccesstoState({ hasUpdateAccess: updateAccess }));
    };

    verifyAccess();
    checkAccessLocal();
  }, [currentUser]);
  function handleSearch() {
    dispatch(getUsers(search.trim()));
  }

  function onChangeActive(e, id) {
    dispatch(updateUser({ isDisabled: !e }, id));
  }
  return (
    <>
      {isAccessable && (
        <MainLayout>
          <HeaderText>Users · {totalUsers}</HeaderText>
          <div className="user-page-wrapper">
            <div className="table-wrapper">
              <div className="table-outer-header">
                <SearchInput
                  icon="end"
                  placeholder="Search by name, Email ID...."
                  className="user-search-input"
                  onChange={(e) => setSearch(e?.target?.value)}
                  onClick={handleSearch}
                />
                <button
                  onClick={() => setModalOpen(true)}
                  className="button yellow-button"
                >
                  + Add New User
                </button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Password</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <Spin fullscreen size="large" />
                  ) : (
                    <>
                      {allUsers?.length ? (
                        allUsers?.map((user) => (
                          <tr key={user.id}>
                            <td>{user.user_name} </td>
                            <td className="link">
                              {user.first_name} {user.last_name}{" "}
                            </td>
                            <td>{user.email}</td>
                            <td>xxxxxxxxxxxxxxxxx </td>
                            <td>
                              <div className="action-btn">
                                <img
                                  className="edit-icon"
                                  onClick={() => updateUserFunc(user.id)}
                                  src={EditIcon}
                                  alt=""
                                />
                                <img
                                  className="trash-icon"
                                  src={TrashIcon}
                                  onClick={() => onDelete(user.id)}
                                  alt=""
                                />
                                <Switch
                                  checked={!user?.isDisabled}
                                  checkedChildren="Active"
                                  unCheckedChildren="Inactive"
                                  defaultChecked
                                  onChange={(status) =>
                                    onChangeActive(status, user.id)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="empty">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="user-table-footer">
              {/* <button className="link-underline">Download Admin Manual</button> */}
              <div className="buttons">
                {/* <button className="button primary-outline-btn">
                Upgrade Database
              </button> */}
                {hasUpdateAccess && allUsers?.length && !isLoading ? (
                  <button
                    className="button primary-outline-btn"
                    onClick={() => setInventoryModal(true)}
                  >
                    Manage Lists
                  </button>
                ) : null}
              </div>
              <InventoryModal
                openModal={openInventoryModal}
                setInventoryModal={setInventoryModal}
              />
            </div>
            {!isLoading &&
              allUsers?.length > 0 &&
              totalUsers > 10 &&
              isAccessable && (
                <div className="pagination">
                  <Pagination
                    defaultCurrent={currentPage}
                    total={totalUsers}
                    // defaultPageSize={2}
                    onChange={handlePageChange}
                  />
                </div>
              )}
          </div>
          <Drawer
            title="Update Details"
            placement="right"
            onClose={onClose}
            open={open}
          >
            <div className="user-details">
              <InputText
                label="First Name"
                name="first_name"
                value={selecetedUser.first_name}
                onChange={handleChangeUpdate}
              />
              <InputText
                label="Last Name"
                name="last_name"
                value={selecetedUser.last_name}
                onChange={handleChangeUpdate}
              />
              <InputText
                label="User Name"
                name="user_name"
                value={selecetedUser.user_name}
                onChange={handleChangeUpdate}
              />
              <InputText
                label="Location"
                name="location"
                value={selecetedUser.location}
                onChange={handleChangeUpdate}
              />
              <div className="w-100">
                <label className="label" htmlFor="">
                  Designation
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={designation.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                  fieldNames={{ name: "Designation" }}
                  onChange={(x, item) =>
                    handleChangeUpdate({
                      target: { value: item.value, name: "designation" },
                    })
                  }
                  value={selecetedUser?.designation}
                />
              </div>
              {/*  */}
              <InputText
                label="email"
                name="email"
                value={selecetedUser.email}
                onChange={handleChangeUpdate}
              />
              <InputText
                label="password"
                name="password"
                onChange={handleChangeUpdate}
              />
              <div className="email-field">
                {/* <label className="label" htmlFor="">
                Email ID
              </label> */}
                {/* <div className="email">
                <div>
                  jessica.m.green31.ctr@mail.mil <img src={CheckIcon} alt="" />
                </div>{" "}
                <button>Update</button>
              </div> */}
              </div>
              <div className="assigned-field">
                {selecetedUser?.designation !== "SUBADMIN" &&
                  designation?.filter(
                    (item) => item?.id == selecetedUser?.designation
                  )?.[0]?.description !== "SUBADMIN" && (
                    <>
                      <h4 className="assigned-header">Assigned</h4>
                      <div className="checkboxes">
                        {checkboxOptions.map((i) => (
                          <div key={i.label} className="checkbox">
                            <Checkbox
                              // defaultChecked={true}
                              // checked={true}
                              checked={i.checked}
                              onChange={() => handleChecked(i)}
                            />
                            <div
                              onClick={() => handleChecked(i)}
                              className="checkbox-label"
                            >
                              {i.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                <div className="footer-buttons">
                  <button
                    className="button primary-button"
                    onClick={handleUpdate}
                  >
                    Save Changes
                  </button>
                  <button
                    className="button secondary-outline-btn"
                    onClick={() => onDelete(selecetedUser?.id)}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            </div>
          </Drawer>
          <Modal
            width={480}
            title="Add New User"
            centered
            open={modalOpen}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={() => {
              dispatch(defaultUser());
              setModalOpen(false);
            }}
            okText="Add User"
            footer={(_, { OkBtn }) => (
              <>
                <OkBtn />
              </>
            )}
          >
            <div className="add-user-form">
              <InputText
                label="User Name"
                value={userDetails.user_name}
                name="user_name"
                onChange={handleChange}
              />
              {error.user_name && (
                <p className="error-message">{error.user_name}</p>
              )}
              <InputText
                label="First Name"
                value={userDetails.first_name}
                name="first_name"
                onChange={handleChange}
              />
              {error.first_name && (
                <p className="error-message">{error.first_name}</p>
              )}
              <InputText
                label="Last Name"
                value={userDetails.last_name}
                name="last_name"
                onChange={handleChange}
              />
              {error.last_name && (
                <p className="error-message">{error.last_name}</p>
              )}
              <InputText
                label="Location"
                name="location"
                value={userDetails.location}
                onChange={handleChange}
              />
              {error.location && (
                <p className="error-message">{error.location}</p>
              )}
              <div className="w-100">
                <label className="label" htmlFor="">
                  Designation
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={designation?.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                  fieldNames={{ name: "Designation" }}
                  onChange={(x, item) =>
                    handleChange({
                      target: { value: item?.value, name: "designation" },
                    })
                  }
                  value={userDetails?.designation}
                />
                {error.designation && (
                  <p className="error-message">{error.designation}</p>
                )}
              </div>
              <InputText
                label="Email ID"
                value={userDetails.email}
                name="email"
                type="email"
                onChange={handleChange}
              />
              {error.email && <p className="error-message">{error.email}</p>}
              <InputText
                label="Password"
                value={userDetails.password}
                name="password"
                type="password"
                onChange={handleChange}
              />
              {error.password && (
                <p className="error-message">{error.password}</p>
              )}
            </div>
          </Modal>
        </MainLayout>
      )}
    </>
  );
}

export default Users;
