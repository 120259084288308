import { Modal, Spin } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import DocsIcon from "@/assets/images/docs-grey-icon.svg";
import FPKIcon from "@/assets/images/fpk-grey-icon.svg";
import MapIcon from "@/assets/images/map-grey-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBuildingResourceModal,
  defaultBuilding,
  getBuildingData,
  getBuildingDataCount,
  setBuildingId,
} from "../../redux/slice/buildingSlice";
import { Link, useLocation } from "react-router-dom/dist";
import { getFirstPathSegment } from "../../services/Utils";
import CheckIcon from "../icons/checkIcon";
import CrossIcon from "../icons/crossIcon";
import MyDocument from "../../pages/asbestos/notice/MyDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getSampleDes } from "../../redux/slice/sampleSlice";
import jsPDF from "jspdf";

export default function BuildingModal({ buildingId }) {
  console.log(buildingId,"aoaoao")
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const {
    buildingResourcesModal,
    buildingDetails,
    buildingDataCount,
    defaultBuildingId,
    isLoading,
  } = useSelector((state) => state.building);
  const { isLoading: loading, noticeData } = useSelector(
    (state) => state.sample
  );

  useEffect(() => {
    console.log(buildingId,"ashjashj")
    if (buildingId) {
      // setLocalBuildingId(buildingId)
      dispatch(setBuildingId(buildingId));
      dispatch(getBuildingDataCount(pathName, buildingId, pathName));
      dispatch(getBuildingData(buildingId));
      dispatch(getSampleDes(buildingId));
    }
  }, [buildingId]);
  console.log(buildingDetails, "details");
  return (
    <>
      {isLoading && buildingResourcesModal ? (
        <Spin delay={20} fullscreen size="large" />
      ) : (
        // <Skeleton count={5} />
        // <Skeleton active style={{ fontSize: '20px', lineHeight: '10' }} />
        ""
      )}
      {!isLoading && buildingResourcesModal ? (
        <Modal
          width={1340}
          className="update-building-modal"
          centered
          open={buildingResourcesModal}
          onCancel={() => {
            dispatch(closeBuildingResourceModal());
          }}
          footer={false}
        >
          <div className="upper-header">
            {/* ID */}
            <div className="title">{`${buildingDetails?.BUILDING_ID || "N/A"} Building Details`}</div>

            <Link
              to={`/${pathName}/buildings/update/${buildingId}`}
              className="link"
            >
              Update Building
            </Link>
          </div>
          <div className="header-content">
            <div className="lower-header flex-grow-1">
              <h3 className="title">
                {buildingDetails?.BUILDING_NAME || "N/A"}
              </h3>
              <div className="details">
                <div className="top">
                  <div className="details-item">
                    <span>
                      {buildingDetails?.ADDRESS
                        ? buildingDetails.ADDRESS
                        : "N/A"}
                      {buildingDetails?.ADDRESS && buildingDetails?.CITY
                        ? ", "
                        : ""}
                      {buildingDetails?.CITY || ""}
                    </span>
                  </div>
                </div>
                <div className="bottom">
                  <div className="details-item">
                    <img src={MapIcon} alt="" />
                    <span>
                      {buildingDetails?.STATE ? buildingDetails.STATE : "N/A"}
                      {buildingDetails?.STATE && buildingDetails?.ZIP
                        ? " "
                        : ""}
                      {buildingDetails?.ZIP ? buildingDetails.ZIP : ""}
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="details-list">
              <p>
                {buildingDetails?.has_full_survey ? (
                  <CheckIcon />
                ) : (
                  <CrossIcon />
                )}{" "}
                Full building survey
              </p>
              <p>
                {" "}
                {buildingDetails?.DEMOLISHED ? <CheckIcon /> : <CrossIcon />}
                Demolished
              </p>
              <p>
                {" "}
                {buildingDetails?.CERTIFIED ? <CheckIcon /> : <CrossIcon />}
                Certified
              </p>
            </div>
          </div>
          <div className="body-content">
            <div className="button-rows">
              <label htmlFor="" className="label">
                Building Information
              </label>
              <div className="buttons">
                {/* No Samples Yet Add One Now ? */}
                {/* /lead/surveys */}
                {[
                  {
                    name: `Surveys · ${buildingDataCount.surveyCount || 0}`,
                    src: `/${pathName}/surveys?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: `Samples · ${buildingDataCount.sampleCount || 0}`,
                    src: `/${pathName}/samples?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: `${buildingDataCount.sampleCount > 0 ? `Results · ${buildingDataCount.resultCount || 0}` : "Add a Sample to Attatch Results "}`,
                    src: `/${pathName}/${buildingDataCount.sampleCount > 0 ? "results" : "samples/create"}?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: `${buildingDataCount.sampleCount > 0 ? `Physical Condition Assesments ·  ${buildingDataCount.assessmentCount || 0}` : "Add a Sample to Create A Hazardous Material Assessment"}`,
                    src: `/${pathName}/${buildingDataCount.sampleCount > 0 ? "assessment" : "samples/create"}?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  // {
                  //   name: `Physical Condition Assesments · ${buildingDataCount.assessmentCount || 0}`,
                  //   src: `/asbestos/assessment?id=${defaultBuildingId}`,
                  //   state:{buildingId,building:buildingDetails.BUILDING_ID}
                  // },
                ].map((i, index) => (
                  <Link
                    key={index}
                    to={i.src}
                    state={i.state}
                    //  onClick={() => dispatch(closeBuildingResourceModal())}
                  >
                    {i.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="button-rows my-4">
              <label htmlFor="" className="label">
                Tables & Reports
              </label>
              <div className="buttons">
                {" "}
                {[
                  {
                    name: "View Table 1",
                    src: `/${pathName}/table-one/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: "View Table 2",
                    src: `/${pathName}/table-two/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: "View Table 3",
                    src: `/${pathName}/table-three/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: "View Lab Report",
                    src: `/${pathName}/lab-report/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },

                  {
                    name: "Download Notice to Occupants",
                    src: "",
                    component: (
                      <PDFDownloadLink
                        document={<MyDocument data={noticeData} />}
                        fileName="notice.pdf"
                      >
                        {({ loading }) =>
                          loading
                            ? "Loading document..."
                            : "Download Notice to Occupants!"
                        }
                      </PDFDownloadLink>
                    ),
                  },
                  // {
                  //   name: "Download Notice to Occupants",
                  //   src: "",
                  //   component: (
                  //     <button key="download-pdf" className="link" onClick={() => generatePDF(noticeData)}>
                  //       Download Notice to Occupants!
                  //     </button>
                  //   ),
                  // },
                ].map((i, index) =>
                  i.component ? (
                    <div key={index} className="link">
                      {i.component}
                    </div>
                  ) : (
                    <Link key={index} to={i.src} state={i.state}>
                      {i.name}
                    </Link>
                  )
                )}
              </div>
            </div>
            <div className="button-rows">
              <label htmlFor="" className="label">
                Attachments
              </label>
              <div className="buttons">
                {[
                  {
                    name: `Asbestos Attachment · ${buildingDataCount.asbestosAtcCount || 0}`,
                    src: `/${pathName}/asbestos-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      message:
                        "By selecting Yes, you are certifying that you reviewed the data and validate that Asbestos was detected in the reports",
                      item: "asbestos",
                    },
                  },

                  {
                    name: `Lead Attachment · ${buildingDataCount.leadAtcCount || 0}`,
                    src: `/${pathName}/lead-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      message:
                        "By selecting Yes, you are certifying that you reviewed the data and validate that Lead was detected in the reports",
                      item: "lead",
                    },
                  },
                  {
                    name: `Mold Attachment · ${buildingDataCount.moldAtcCount || 0}`,
                    src: `/${pathName}/mold-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      message:
                        "By selecting Yes, you are certifying that you reviewed the data and validate that Mold was remidiated as indicated by the reports",
                      item: "mold",
                    },
                  },
                  {
                    name: `REC Attachment · ${buildingDataCount.recAtcount || 0}`,
                    src: `/${pathName}/rec-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      item: "rec",
                    },
                  },
                  {
                    name: `Asbestos Disposal Verfication Form · ${buildingDataCount.AdvfATCCount || 0}`,
                    src: `/${pathName}/advf-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      item: "ADVF",
                    },
                  },
                ].map((i, index) => (
                  // <Link key={i.name} state={i.state} to={i.src}>
                  //   {i.name}
                  // </Link>
                  <Link
                    key={index}
                    to={i.src}
                    state={i.state}
                    //  onClick={() => dispatch(closeBuildingResourceModal())}
                  >
                    {i.name}
                  </Link>
                ))}
                {/*  */}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
