import React, { useState } from "react";
import OtpInput from "../../components/OtpInput";
import { InputText } from "../../components";
import API from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import { setGlobalState } from "../../redux/slice/globalStates";

function OtpContent({ showVerifyEmail }) {
  const dispatch = useDispatch();

  const { forgotEmail, path } = useSelector((state) => state.globalStates);
  const [details, setDetails] = useState({
    otp: 0,
    password: "",
    email: forgotEmail,
  });

  async function resendEmail(e) {
    e.preventDefault()
    const response = await API.post(ApiEndPoint.forgotPassword, {
      email: forgotEmail,
    });
    openNotificationWithIcon(
      "success",
      "Password reset email sent. Check your inbox."
    );
  }
  const handleSubmit = async () => {
    try {
      if ((details.otp + "").length < 4) {
        openNotificationWithIcon("error", "Please enter valid otp");
        return;
      }
      if (details.password.length < 5) {
        openNotificationWithIcon(
          "error",
          "Password must contain atleast 5 characters"
        );
        return;
      }
      const response = await API.post(ApiEndPoint.verifyUser, details);
      openNotificationWithIcon(
        "success",
        path === "login"
          ? "You are verified now login to continue"
          : "Password Changed Successfully"
      );
      dispatch(
        setGlobalState({
          showOTPmodalOpen: false,
          forgotEmail: null,
          showForgotPassword: false,
          path: null,
        })
      );
      setDetails({ otp: 0, password: "", email: null });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
    // setOtp(otp);
  };
  return (
    <form className="forgot-password-wrapper">
      <h3 className="login-header" style={{ letterSpacing: "1px" }}>
        {showVerifyEmail ? "Verify Your Email!" : "Forgot Your Password!"}{" "}
      </h3>
      <p className="login-desc">
        We have sent a{" "}
        {showVerifyEmail ? "verification code" : "resent password code"} to your
        registered <br></br>
        Email ID : <strong>{forgotEmail}</strong>
      </p>

      <div className="otp-inputs">
        <OtpInput
          length={4}
          onOtpChange={(otp) => setDetails((prev) => ({ ...prev, otp: +otp }))}
        />
        {/* {errors.email && <p className="error-message">{errors.email}</p>} */}
      </div>
      <div className="login-input mb-4">
        <InputText
          label="Enter new Password"
          type="text"
          placeholder="XXXXXXXXXXX"
          onChange={(e) => {
            setDetails((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        {/* {errors.email && <p className="error-message">{errors.email}</p>} */}
      </div>

      <div className="login-button">
        <button
          className="primary-button button w-100 my-4"
          type="button"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <div className="forgot-link">
          <button onClick={(e) => resendEmail(e)}>Resend Email</button>
        </div>
      </div>
    </form>
  );
}

export default OtpContent;
