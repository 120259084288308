import React, { useEffect, useLayoutEffect, useState } from "react";
import "./assessment.scss";
import { ExclamationCircleFilled } from '@ant-design/icons';
import CheckboxIcon from "@/assets/images/checkbox-icon.svg";
import { Checkbox, GoBack, HeaderText, InputText, MainLayout } from "../../../components";
import { DatePicker, Select, Modal } from "antd";
import DetailsCard from "../results/subComponent/detailsCard";
import { addAssessment, addAssessmentDetails, defaultAssessment, deleteAssessment, getQuesAnsData, getSampleAreas, getSingleAssementDetail, setErrors, updateAssessment } from "../../../redux/slice/assessmentSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { openNotificationWithIcon } from "../../../services/toast";
import { getFirstPathSegment } from "../../../services/Utils";
import dayjs from "dayjs";
const { confirm } = Modal
function CreateAssessment() {
  const location = useLocation()
  const pathName = getFirstPathSegment(location?.pathname)
  const params = useParams()
  const [changedData, setChangedData] = useState({})
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get('id');
  const building = location?.state?.building
  const Navigate = useNavigate()
  const { quesAns, assessmentDetails, errors, sampleAreas } = useSelector((state) => state.assessment)
  const [assessmentId, setAssessmentId] = useState(null)
  const dispatch = useDispatch()
  // ads 
  function getQuesAns() {
    dispatch(getQuesAnsData())
    dispatch(getSampleAreas(buildingId))
  }

  useEffect(() => {
    getQuesAns()
    if (params.id) {


      (async () => {
        setAssessmentId(Number(params.id))

        const assessmentDetailError = await dispatch(getSingleAssementDetail(Number(params.id)))
        if (assessmentDetailError) openNotificationWithIcon("error", assessmentDetailError);


      })()
      // console.log(data.then((dat)=>{
      //   console.log(dat,"pop")
      // }),"pppp")
    }
    const obj = { BUILDING_ID: buildingId + "" }
    dispatch(addAssessmentDetails(obj))
    return (() => {
      dispatch(setErrors({}, "fullObj"))
      dispatch(defaultAssessment())
    })
  }, [])

  // function handleChange(e, date) {
  //   console.log(e, "eeeee")
  //   let obj
  //   if (date || date == "") {
  //     obj = { "INSPECTION_DATE": date }
  //     dispatch(setErrors({ INSPECTION_DATE: null }, "..."))
  //   } else {


  //     let { name, value } = e.target
  //     // if (name === 'AREA' || name === "DESCRIPTION") {
  //     //   obj = { [name]: value }
  //     // } else {
  //     if (name === 'DAMAGE_TOTAL' || name === 'EXPOSURE_TOTAL') {
  //       let max = name === 'DAMAGE_TOTAL' ? 17 : 21
  //       value = +value;
  //       if (value < 0) value = 0;
  //       if (value > max) value = max;
  //     }
  //     if (name === 'DESCRIPTION') {
  //       obj = { [name]: value }
  //     } else {
  //       obj = { [name]: +value }

  //     }

  //     // }
  //     dispatch(setErrors({ [name]: null }, "..."))
  //   }
  //   if (assessmentId) {
  //     setChangedData((prev) => ({ ...prev, ...obj }))
  //   }

  //   dispatch(addAssessmentDetails(obj))
  // }



  // useEffect(() => {

  //   if (!changedData?.DAMAGE_TOTAL && assessmentDetails?.DAMAGE_TOTAL) {
  //     dispatch(addAssessmentDetails({ DAMAGE_TOTAL: assessmentDetails.DAMAGE_TOTAL }));
  //   }

  //   if (!changedData?.EXPOSURE_TOTAL && assessmentDetails?.EXPOSURE_TOTAL) {
  //     dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: assessmentDetails.EXPOSURE_TOTAL }));
  //   }


  //   let quesAnsArr = Object.values(quesAns)?.filter((item) => item.CATEGORY_ID < 7)
  //   let total = assessmentDetails?.DAMAGE_TOTAL || 0;
  //   let maxPointsForCategories3And4 = 0;
  //   for (let i = 0; i < quesAnsArr.length; i++) {
  //     let maxPoints = 0;
  //     if (quesAnsArr[i].CATEGORY_ID == 3 || quesAnsArr[i].CATEGORY_ID == 4) {

  //       quesAnsArr[i].OPTIONS.forEach(option => {
  //         if (option.ASSESSMENT_ITEM_ID == assessmentDetails[i + 1]) {
  //           maxPoints = Math.max(maxPoints, Number(option.POINTS));
  //         }
  //       });

  //       maxPointsForCategories3And4 = Math.max(maxPointsForCategories3And4, maxPoints);
  //     } else {
  //       for (let j = 0; j < quesAnsArr[i].OPTIONS.length; j++) {
  //         if (quesAnsArr[i].OPTIONS[j].ASSESSMENT_ITEM_ID == assessmentDetails[i + 1]) {

  //           total += Number(quesAnsArr[i].OPTIONS[j].POINTS)
  //         }
  //       }
  //     }
  //   }
  //   total += maxPointsForCategories3And4
  //   dispatch(addAssessmentDetails({ DAMAGE_TOTAL: total }));

  //   /////////////////////////////////////////////////////////////////

  //   let quesAnsArrgt6 = Object.values(quesAns)?.filter((item) => item.CATEGORY_ID > 6)
  //   let totalgt6 = assessmentDetails?.EXPOSURE_TOTAL || 0;
  //   let maxPointsForCategories3And4gt6 = 0;
  //   for (let i = 0; i < quesAnsArrgt6.length; i++) {
  //     let maxPoints = 0;
  //     if (quesAnsArrgt6[i].CATEGORY_ID == 12 || quesAnsArrgt6[i].CATEGORY_ID == 13) {

  //       quesAnsArrgt6[i].OPTIONS.forEach(option => {
  //         if (option.ASSESSMENT_ITEM_ID == assessmentDetails[i + 7]) {
  //           maxPoints = Math.max(maxPoints, Number(option.POINTS));
  //         }
  //       });

  //       maxPointsForCategories3And4gt6 = Math.max(maxPointsForCategories3And4gt6, maxPoints);
  //     } else {
  //       for (let j = 0; j < quesAnsArrgt6[i].OPTIONS.length; j++) {
  //         if (quesAnsArrgt6[i].OPTIONS[j].ASSESSMENT_ITEM_ID == assessmentDetails[i + 7]) {
  //           totalgt6 += Number(quesAnsArrgt6[i].OPTIONS[j].POINTS)
  //         }
  //       }
  //     }
  //   }
  //   totalgt6 += maxPointsForCategories3And4gt6
  //   dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: totalgt6 }));
  // }, [changedData])
  // function handleChange(e, date) {

  //   if (date !== undefined) {
  //     setChangedData(prev => ({ ...prev, INSPECTION_DATE: date }));
  //     dispatch(addAssessmentDetails({ INSPECTION_DATE: date }));
  //     dispatch(setErrors({ INSPECTION_DATE: null }, "..."))
  //   } else {
  //     let { name, value, type } = e.target;
  //     console.log(type, "type")
  //     if (e.target.type === 'radio') {
  //       setChangedData(prev => ({ ...prev, [name]: value }));
  //       dispatch(addAssessmentDetails({ [name]: value }));
  //       dispatch(setErrors({ [name]: null }, "..."))

  //       // const data = Object.values(quesAns).map((item)=>item)
  //       // console.log(data,"data")
  //       // Check if this change affects Damage or Exposure totals
  //       // const question = data?.find(q => q?.CATEGORY_ID == name);
  //       // if (damageCategories.includes(question.CATEGORY_ID)) {
  //       //     calculateTotal('DAMAGE_TOTAL', damageCategories);
  //       // } else if (exposureCategories.includes(question.CATEGORY_ID)) {
  //       //     calculateTotal('EXPOSURE_TOTAL', exposureCategories);
  //       // }


  //       //
  //       // let particularQues = quesAnsArr?.find((item) => item?.CATEGORY_ID == name)
  //       // let selectedOptionPoint = particularQues?.OPTIONS?.find((item) => item?.ASSESSMENT_ITEM_ID == value)?.POINTS


  //       let DAMAGE_TOTAL




  //       // console.log(total, "totalssss")
  //       // if(particularQues.CATEGORY_ID==5 || particularQues.CATEGORY_ID==6){
  //       //   let proximityItems=Math.max(assessmentDetails[5]+assessmentDetails[6])
  //       //   DAMAGE_TOTAL +=proximityItems
  //       // }
  //       console.log(DAMAGE_TOTAL, "damage")


  //       // let total;

  //       //
  //     } else if (e.target.type === 'text') {
  //       setChangedData(prev => ({ ...prev, [name]: value }));
  //       dispatch(addAssessmentDetails({ [name]: value }));
  //       dispatch(setErrors({ [name]: null }, "..."))
  //     }
  //   }
  // }

  function getCategoryPoints(quesAns, categoryId, selectedValue) {
    const question = quesAns[categoryId]; // Access the question by CATEGORY_ID
    if (!question) return 0;
    const option = question?.OPTIONS?.find((o) => o.ASSESSMENT_ITEM_ID == selectedValue);
    return option ? Number(option.POINTS) : 0;
  }


  //   useEffect(() => {
  //     // Set initial DAMAGE_TOTAL and EXPOSURE_TOTAL if not already set
  //     if (!changedData?.DAMAGE_TOTAL && assessmentDetails?.DAMAGE_TOTAL) {
  //       dispatch(addAssessmentDetails({ DAMAGE_TOTAL: assessmentDetails.DAMAGE_TOTAL }));
  //     }
  //     if (!changedData?.EXPOSURE_TOTAL && assessmentDetails?.EXPOSURE_TOTAL) {
  //       dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: assessmentDetails.EXPOSURE_TOTAL }));
  //     }
  // console.log(assessmentDetails.DAMAGE_TOTAL,"assessmentDetails.DAMAGE_TOTAL")
  //     // Calculate DAMAGE_TOTAL (categories 1-6)
  //     let totalDamage = assessmentDetails.DAMAGE_TOTAL || 0;
  //     let maxPointsForCategories3And4 = 0;

  //     Object.keys(assessmentDetails).forEach((key) => {
  //       const categoryId = parseInt(key);
  //       if (categoryId <= 6 && assessmentDetails[key]) {
  //         const categoryPoints = getCategoryPoints(quesAns, categoryId, assessmentDetails[key]);

  //         // Handle categories 3 and 4 for max points
  //         if (categoryId === 3 || categoryId === 4) {
  //           maxPointsForCategories3And4 = Math.max(maxPointsForCategories3And4, categoryPoints);
  //         } else {
  //           totalDamage += categoryPoints;
  //         }
  //       }
  //     });

  //     totalDamage += maxPointsForCategories3And4;
  //     dispatch(addAssessmentDetails({ DAMAGE_TOTAL: totalDamage }));

  //     /////////////////////////////////////////////////////////////////

  //     let totalExposure = assessmentDetails.EXPOSURE_TOTAL || 0;
  //     let maxPointsForCategories12And13 = 0;

  //     Object.keys(assessmentDetails).forEach((key) => {
  //       const categoryId = parseInt(key);
  //       if (categoryId >= 7 && assessmentDetails[key]) {
  //         const categoryPoints = getCategoryPoints(quesAns, categoryId, assessmentDetails[key]);


  //         if (categoryId === 12 || categoryId === 13) {
  //           maxPointsForCategories12And13 = Math.max(maxPointsForCategories12And13, categoryPoints);
  //         } else {
  //           totalExposure += categoryPoints;
  //         }
  //       }
  //     });

  //     totalExposure += maxPointsForCategories12And13;
  //     dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: totalExposure }));
  //   }, [changedData]);

  // function handleChange(e, date) {
  //   if (date !== undefined) {
  //     setChangedData((prev) => ({ ...prev, INSPECTION_DATE: date }));
  //     dispatch(addAssessmentDetails({ INSPECTION_DATE: date }));
  //     dispatch(setErrors({ INSPECTION_DATE: null }, "..."));
  //   } else {


  //     let { name, value, type } = e.target;
  //     if (type === "radio") {

  //       const previousValue = assessmentDetails[name];
  //       setChangedData((prev) => ({ ...prev, [name]: value }));
  //       dispatch(addAssessmentDetails({ [name]: value }));
  //       dispatch(setErrors({ [name]: null }, "..."));


  //       const categoryPoints = getCategoryPoints(quesAns, name, value);
  //       const previousPoints = previousValue
  //         ? getCategoryPoints(quesAns, name, previousValue)
  //         : 0;

  //       if (name <= 6) {

  //         const newDamageTotal = (assessmentDetails.DAMAGE_TOTAL || 0) - previousPoints + categoryPoints;
  //         dispatch(addAssessmentDetails({ DAMAGE_TOTAL: newDamageTotal }));
  //       } else if (name >= 7) {

  //         const newExposureTotal = (assessmentDetails.EXPOSURE_TOTAL || 0) - previousPoints + categoryPoints;
  //         dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: newExposureTotal }));
  //       }
  //     } else if (type === "text") {
  //       setChangedData(prev => ({ ...prev, [name]: value }));
  //       dispatch(addAssessmentDetails({ [name]: value }));
  //       dispatch(setErrors({ [name]: null }, "..."));
  //     }
  //   }
  // }


  function handleChange(e, date) {
    if (date !== undefined) {
      setChangedData((prev) => ({ ...prev, INSPECTION_DATE: date }));
      dispatch(addAssessmentDetails({ INSPECTION_DATE: date }));
      dispatch(setErrors({ INSPECTION_DATE: null }, "..."));
    } else {
      let { name, value, type } = e.target;

      if (type === "radio") {

        const previousValue = assessmentDetails[name] ?? assessmentDetails?.hazardousAssessments?.[name]; // Get the previously selected option value
        console.log(assessmentDetails, "opop")
        setChangedData((prev) => ({ ...prev, [name]: value }));
        dispatch(addAssessmentDetails({ [name]: value }));
        dispatch(setErrors({ [name]: null }, "..."));

        // Get points for the new option and the previous option
        const categoryPoints = getCategoryPoints(quesAns, name, value); // Get the points of the newly selected option
        const previousPoints = previousValue ? getCategoryPoints(quesAns, name, previousValue) : 0; // Get points for the previously selected option
        console.log(previousPoints, "prevvvvvious")
        if (name <= 6) {
          let newDamageTotal = 0

          // new logic

          // let maxPointsForCategories3And4 = 0;

          newDamageTotal = (assessmentDetails.DAMAGE_TOTAL || 0) - previousPoints + categoryPoints; // Subtract previous points and add the new points


          // if (name === 3 || name === 4) {
          //   maxPointsForCategories3And4 = Math.max(maxPointsForCategories3And4, categoryPoints);
          // } else {
          //   totalDamage += categoryPoints;
          // }
          // 


if(newDamageTotal>17){
  newDamageTotal=17
}

          dispatch(addAssessmentDetails({ DAMAGE_TOTAL: newDamageTotal })); // Update damage total
        }
        // If the category is for exposure total (categories 7-13)
        else if (name >= 7) {
          let newExposureTotal = (assessmentDetails.EXPOSURE_TOTAL || 0) - previousPoints + categoryPoints; // Subtract previous points and add the new points


          // new logic

          // let maxPointsForCategories12And13 = 0;



          // if (name === 12 || name === 13) {
          //   maxPointsForCategories12And13 = Math.max(maxPointsForCategories12And13, categoryPoints);
          // } else {
          //   newExposureTotal += categoryPoints;
          // }


          // 

if(newExposureTotal>21){
  newExposureTotal =21
}

          dispatch(addAssessmentDetails({ EXPOSURE_TOTAL: newExposureTotal })); // Update exposure total
        }
      } else if (type === "text") {
        setChangedData((prev) => ({ ...prev, [name]: value }));
        dispatch(addAssessmentDetails({ [name]: value }));
        dispatch(setErrors({ [name]: null }, "..."));
      }
    }
  }


  function calculateTotal(totalField, categoryList) {
    let total = 0;
    categoryList.forEach(categoryId => {
      const selectedOptionId = assessmentDetails[categoryId];
      let quesAnsArr = Object.values(quesAns)?.map((item) => item)
      const question = quesAnsArr?.find(q => q.CATEGORY_ID === categoryId);
      const option = question?.OPTIONS?.find(o => o.ASSESSMENT_ITEM_ID === selectedOptionId);
      if (option) {
        total += option.POINTS;
      }
    });
    dispatch(addAssessmentDetails({ [totalField]: total }));
  }

  function validateData(assessmentDetails) {
    let errors = {}
    let firstErrorField = null
    for (const key in assessmentDetails) {

      if (assessmentDetails[key] === null || assessmentDetails[key] === undefined || assessmentDetails[key] === '') {
        if (isNaN(key)) {

          errors[key] = `${key} is required`
        }
        if (!isNaN(key)) {
          errors[key] = `This Question is required`
        }
        firstErrorField = key
      }
    }
    dispatch(setErrors(errors, "fullObj"))
    return firstErrorField
  }

  function navigateToAssessments() {
    Navigate(`/${pathName}/assessment?id=${buildingId}`,
      {
        state:
          { building }
      })
  }
  function handleSubmit() {
    const validate = validateData(assessmentDetails)
    if (validate) {
      console.log(validate, "===validate===")
      let elementsByName = document.getElementsByName(validate)
      console.log(elementsByName, "ele")
      if (elementsByName && elementsByName.length > 0) {
        const firstElement = elementsByName[0]
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      return
    } else {
      if (assessmentId) {
        dispatch(updateAssessment(assessmentId, changedData))
      }
      else {
        dispatch(addAssessment())
      }
      navigateToAssessments()
    }
  }
  // function disabledDate(current) {
  //   return current && current > new Date()
  // }

  function handleDelete() {
    confirm({
      title: 'Are you sure you want to delete this assessment?',
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAssessmentFunc()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }



  function deleteAssessmentFunc() {
    dispatch(deleteAssessment(assessmentId, buildingId))
    navigateToAssessments()
  }


  function handleAreaChange(e) {
    const { name, value, checked } = e.target
    console.log(name, value, checked, "name, value, checked")
    let updatedAreas = [...assessmentDetails.AREA]

    if (checked) {
      updatedAreas.push(Number(value))
    } else {
      const index = updatedAreas.indexOf(Number(value))
      if (index !== -1) {
        updatedAreas.splice(index, 1)
      }
    }
    let obj = { AREA: updatedAreas }
    dispatch(addAssessmentDetails(obj));
    if (assessmentId) {
      setChangedData((prev) => ({ ...prev, ...obj }))
    }
    dispatch(setErrors({ [name]: null }, "..."))
  }

  return (
    <MainLayout>
      <div className="create-assessment-wrapper">
        <HeaderText>
          <GoBack />
          <span className="survey-title">
            {
              !assessmentId ?
                "Add New Assessment"
                : "Update Assessment"
            }
            .{building}
            {/* · FPK 23A Pavillion */}
          </span>
        </HeaderText>
        <div className="content ">
          <div className="first-card">
            <div className="row" style={{ rowGap: '1rem' }}>
              {/* <div className="col-4">
                <InputText label="Select Area" name='AREA' errors={errors.AREA} value={assessmentDetails?.AREA} onChange={handleChange} />
              </div> */}
              {
                assessmentId ?
                  <InputText label="AREA" name='AREA' errors={errors?.AREA} disabled={true} onChange={handleChange} value={assessmentDetails?.AREA} />
                  :
                  sampleAreas?.map((item) => (
                    <div className="col-2" key={item.AREA_ID}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="checkbox-field">
                          <label
                            htmlFor={item.id}
                            className={assessmentDetails?.AREA?.includes(item.id) ? "checked" : ""}
                          >
                            <img
                              className={assessmentDetails?.AREA?.includes(item.id) ? "d-block" : "d-none"}
                              src={CheckboxIcon}
                              alt=""
                            />
                          </label>
                          {item?.AREA_ID}
                          <input type="checkbox" className="d-none" id={item.id} value={item.id}
                            checked={assessmentDetails?.AREA?.includes(item.id)}
                            name='AREA' onChange={handleAreaChange} />
                          {/* <label htmlFor={item.id}>{item?.AREA_ID}</label> */}
                        </div>
                      </div>
                    </div>
                  ))
              }
              <div className="col-4">
                <label className="label" htmlFor="">
                  INSPECTION DATE
                </label>
                <div style={{ paddingLeft: "7px" }}>
                  <DatePicker size="large"
                    name='INSPECTION_DATE' onChange={handleChange}
                    // value={assessmentDetails?.INSPECTION_DATE ? moment(assessmentDetails?.INSPECTION_DATE, 'YYYY-MM-DD') : null}
                    value={
                      assessmentDetails?.INSPECTION_DATE ? dayjs(assessmentDetails?.INSPECTION_DATE, 'YYYY-MM-DD') : null
                    }
                    format={'YYYY-MM-DD'}
                  />
                  {errors?.INSPECTION_DATE && (
                    <small className="error-message">{errors?.INSPECTION_DATE}</small>
                  )}
                </div>
              </div>
              <div className="col-4">
                <InputText label="DESCRIPTION" name='DESCRIPTION' disabled={assessmentId ? true : false} errors={errors?.DESCRIPTION} onChange={handleChange} value={assessmentDetails?.DESCRIPTION} />
              </div>
            </div>
          </div>

          <DetailsCard header="1. Damage">
            <div className="assessment-card-content">
              <div className="header">{quesAns[1]?.CATEGORY}</div>
              <p className="description">
                {quesAns[1]?.CATEGORY_TEXT}
              </p>
              <div className="inputs" >
                {
                  quesAns[1]?.OPTIONS.map((option) => (
                    < div className="input" key={option.ASSESSMENT_ITEM_ID} >
                      {console.log(assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[1]?.CATEGORY_ID] == option?.ASSESSMENT_ITEM_ID : null, "viiiii")}
                      <input type="radio" name={quesAns[1]?.CATEGORY_ID}
                        value={option?.ASSESSMENT_ITEM_ID}
                        // checked={true}
                        // defaultChecked={true}
                        defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[1]?.CATEGORY_ID] == option?.ASSESSMENT_ITEM_ID : null}
                        onChange={handleChange} id={option?.POINTS} />
                      <label htmlFor={option?.POINTS}>
                        ({option?.POINTS}pt.)
                        {option?.TEXT}
                      </label>
                    </div>
                  ))

                }
                {errors[quesAns[1]?.CATEGORY_ID] && (
                  <small className="error-message">{errors[quesAns[1]?.CATEGORY_ID]}</small>
                )}
              </div>
            </div>
          </DetailsCard>
          <div className="third-card assessment-card-content">
            <div className="header">{quesAns[2]?.CATEGORY}</div>
            <div className="inputs">
              {
                quesAns[2]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[2]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[2]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>

                  </div>
                ))
              }
              {errors[quesAns[2]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[2]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <DetailsCard
            className="proximity-card"
            header={
              <>
                <span>Proximity to items for repair.</span> If both A & B apply,
                score the one with the highest rating.
              </>
            }
          >
            <div className="assessment-card-content">
              <div className="header">
                {quesAns[3]?.CATEGORY}{" "}
                <span>
                  {quesAns[3]?.CATEGORY_TEXT}
                </span>
              </div>

              <div className="inputs">
                {
                  quesAns[3]?.OPTIONS.map((option) => (

                    <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                      <input type="radio"
                        defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[3]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                        name={quesAns[3]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                      <label htmlFor={option?.POINTS}>
                        ({option?.POINTS}pt.)
                        {option?.TEXT}
                      </label>
                    </div>
                  ))
                }
                {errors[quesAns[3]?.CATEGORY_ID] && (
                  <small className="error-message">{errors[quesAns[3]?.CATEGORY_ID]}</small>
                )}

              </div>
            </div>
          </DetailsCard>
          <div className="third-card assessment-card-content">
            <div className="header">
              {quesAns[4]?.CATEGORY}
              <span>
                {quesAns[4]?.CATEGORY_TEXT}
              </span>
            </div>
            <div className="inputs">
              {
                quesAns[4]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[4]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[4]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[4]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[4]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="third-card assessment-card-content">
            <div className="header">{quesAns[5]?.CATEGORY}</div>
            <div className="inputs">
              {
                quesAns[5]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[5]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[5]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[5]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[5]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="third-card assessment-card-content">
            <div className="header">{quesAns[6]?.CATEGORY}</div>
            <div className="inputs">
              {
                quesAns[6]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[6]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null} name={quesAns[6]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[6]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[6]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="fourth-card ">
            <h5>damage total</h5>
            <InputText name='DAMAGE_TOTAL' disabled={true} value={assessmentDetails?.DAMAGE_TOTAL} errors={errors.DAMAGE_TOTAL} type='number' min={0} max={17} onChange={handleChange} />
            {" "}
            {/* <span> {assessmentDetails?.DAMAGE_TOTAL}</span> */}
            <span className="min-max">(MAX: 17 MIN: 0)</span>
          </div>
          <DetailsCard header="1. Exposure">
            <div className="assessment-card-content">
              <div className="header">{quesAns[7]?.CATEGORY}</div>
              <p className="description">
                {quesAns[7]?.CATEGORY_TEXT}
              </p>
              <div className="inputs">
                {
                  quesAns[7]?.OPTIONS.map((option) => (

                    <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                      <input type="radio"
                        defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[7]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                        name={quesAns[7]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                      <label htmlFor={option?.POINTS}>
                        ({option?.POINTS}pt.)
                        {option?.TEXT}
                      </label>
                    </div>
                  ))
                }
                {errors[quesAns[7]?.CATEGORY_ID] && (
                  <small className="error-message">{errors[quesAns[7]?.CATEGORY_ID]}</small>
                )}
              </div>
            </div>
          </DetailsCard>
          <div className="third-card assessment-card-content">
            <div className="header">{quesAns[8]?.CATEGORY}</div>
            <div className="inputs">
              {
                quesAns[8]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[8]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[8]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[8]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[8]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="third-card assessment-card-content">
            <h4 className="header">{quesAns[9]?.CATEGORY}</h4>
            <div className="inputs">
              {
                quesAns[9]?.OPTIONS.map((option) => (
                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[9]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[9]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[9]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[9]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="third-card assessment-card-content">
            <h4 className="header">{quesAns[10]?.CATEGORY}</h4>
            <div className="inputs">
              {
                quesAns[10]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[10]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[10]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[10]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[10]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="third-card assessment-card-content">
            <h4 className="header">{quesAns[11]?.CATEGORY}</h4>
            <div className="inputs">
              {
                quesAns[11]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[11]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[11]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[11]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[11]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <DetailsCard
            className="population-card"
            header={
              <>
                <span>Proximity to items for repair.</span> If This involves
                defining average occupancy as the total number of building
                occupants and outside visitor traffic into a room or area during
                an eight hour period. For example, a reception area in a DEH
                shop has one person assigned to the area. There are 15
                individuals assigned to the building. They have approx. 240
                customers in the building during an eight hour period. On
                average, each customer (visitor) is serviced and departs the
                building within 30 minutes.
              </>
            }
          >
            <div className="assessment-card-content">
              <h4 className="header">{quesAns[12]?.CATEGORY}</h4>

              <div className="inputs">
                {
                  quesAns[12]?.OPTIONS.map((option) => (

                    <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                      <input type="radio"
                        defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[12]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                        name={quesAns[12]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                      <label htmlFor={option?.POINTS}>
                        ({option?.POINTS}pt.)
                        {option?.TEXT}
                      </label>
                    </div>
                  ))
                }
                {errors[quesAns[12]?.CATEGORY_ID] && (
                  <small className="error-message">{errors[quesAns[12]?.CATEGORY_ID]}</small>
                )}
              </div>
            </div>
          </DetailsCard>
          <div className="third-card assessment-card-content">
            <h4 className="header">{quesAns[13]?.CATEGORY}</h4>
            <div className="inputs">
              {
                quesAns[13]?.OPTIONS.map((option) => (

                  <div className="input" key={option.ASSESSMENT_ITEM_ID}>
                    <input type="radio"
                      defaultChecked={assessmentId ? assessmentDetails?.hazardousAssessments?.[quesAns[13]?.CATEGORY_ID] === option?.ASSESSMENT_ITEM_ID : null}
                      name={quesAns[13]?.CATEGORY_ID} value={option?.ASSESSMENT_ITEM_ID} onChange={handleChange} id={option?.POINTS} />
                    <label htmlFor={option?.POINTS}>
                      ({option?.POINTS}pt.)
                      {option?.TEXT}
                    </label>
                  </div>
                ))
              }
              {errors[quesAns[13]?.CATEGORY_ID] && (
                <small className="error-message">{errors[quesAns[13]?.CATEGORY_ID]}</small>
              )}
            </div>
          </div>
          <div className="fourth-card ">
            <h5>Exposure total</h5> <InputText onChange={handleChange} disabled={true} errors={errors?.EXPOSURE_TOTAL} max={25} value={assessmentDetails?.EXPOSURE_TOTAL} name='EXPOSURE_TOTAL' type='number' min={0} />{" "}
            <span className="min-max">(MAX: 21 MIN: 0)</span>
          </div>
          <div className="submit-btn text-center mt-4">
            <button className="button primary-button me-3" onClick={handleSubmit}>
              {
                !assessmentId ?
                  "Save New Assessment" :
                  "Update Assessment"
              }
            </button>
            {assessmentId ?
              <button className="button secondary-button mx-auto" onClick={handleDelete}>
                Delete Assessment
              </button>
              : ""}
          </div>
        </div>
      </div >
    </MainLayout >
  );
}

export default CreateAssessment;
